import { clearUserInfo } from '@twigeducation/ts-auth';
import store from '../../store';

const logUserOutOn401 = (graphqlErrors) => {
    if (graphqlErrors &&
        graphqlErrors[0] &&
        graphqlErrors[0].message &&
        graphqlErrors[0].message.startsWith('401')) {
        store.dispatch(clearUserInfo());
    }
};

export default logUserOutOn401;
