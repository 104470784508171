import React from 'react';
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from '@twigeducation/react-sticky';
import Header from './Header';
import Footer from './Footer';

const Layout = props => (
    <div className="root-container">
        <StickyContainer>
            <Sticky>
                {
                  ({ style }) => (
                      <Header
                        style={style}
                      />
                  )
              }
            </Sticky>
            {props.children}
            <Footer />
        </StickyContainer>
    </div>
);

Layout.propTypes = {
    children: PropTypes.element.isRequired,
};

export default Layout;
