import React from 'react';
import PropTypes from 'prop-types';
import { remove as removeCookie } from 'js-cookie';
import HeaderAuthorized from '../HeaderAuthorized';
import HeaderUnauthorized from '../HeaderUnauthorized';

class HeaderSwitcher extends React.Component {
    constructor(props) {
        super(props);

        this.signOut = this.signOut.bind(this);
    }

    shouldComponentUpdate() {
        return true;
    }

    signOut() {
        removeCookie('greetingMessage');
        this.props.logout();
    }

    render() {
        const { isUserAuthorized, isUserAuthenticated, currentAuthenticationState, isUserLoggedIn } = this.props;
        return isUserAuthorized ?
            <HeaderAuthorized onSignOut={this.signOut} /> :
            <HeaderUnauthorized
              isAuthenticated={isUserAuthenticated}
              onSignOut={this.signOut}
              currentAuthenticationState={currentAuthenticationState}
              isUserLoggedIn={isUserLoggedIn}
            />;
    }
}

HeaderSwitcher.propTypes = {
    isUserAuthorized: PropTypes.bool,
    isUserAuthenticated: PropTypes.bool,
    isUserLoggedIn: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    currentAuthenticationState: PropTypes.string.isRequired,
};

HeaderSwitcher.defaultProps = {
    isUserAuthorized: false,
    isUserAuthenticated: false,
    isUserLoggedIn: false,
};

export default HeaderSwitcher;
