import React from 'react';
import { Link } from 'react-router-dom';

require('./scss/Footer.scss');

const Footer = () => (
    <footer className="footer">
        <div className="footer__wrapper">
            <div className="footer__logo-wrapper">
                <a
                  href="http://www.twigeducation.com/"
                  className="footer__logo"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                    <span className="show-for-sr">Twig Education homepage</span>
                </a>
            </div>

            <nav className="footer__menu" aria-label="Complimentary">
                <ul>
                    <li><Link to="/about-us">About</Link></li>
                    <li><Link to="/contact-us">Contact</Link></li>
                    <li><Link to="/accessibility">Accessibility</Link></li>
                    <li>
                        <a
                          href="https://twigeducation.com/privacy/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                    </li>
                </ul>
            </nav>

            <nav aria-label="Complimentary">
                <ul className="footer__social-menu">
                    <li>
                        <a href="https://facebook.com/twigscience">
                            <span aria-hidden className="icon icon-facebook" />
                            <span className="show-for-sr">Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/TwigScience">
                            <span aria-hidden className="icon icon-twitter" />
                            <span className="show-for-sr">Twitter</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/twig.science/">
                            <span aria-hidden className="icon icon-instagram" />
                            <span className="show-for-sr">Instagram</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.pinterest.co.uk/twigworld/">
                            <span aria-hidden className="icon icon-pinterest" />
                            <span className="show-for-sr">Pintrest</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCUcNTfvHvOivRCJMyPzXbsQ?view_as=subscriber">
                            <span aria-hidden className="icon icon-youtube" />
                            <span className="show-for-sr">Youtube</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/1342611/">
                            <span aria-hidden className="icon icon-linkedin" />
                            <span className="show-for-sr">LinkedIn</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </footer>
);

export default Footer;
