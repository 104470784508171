import React from 'react';
import PropTypes from 'prop-types';
import SearchUrl from '../../apps/Search/url';

require('./scss/SearchForm.scss');

class SearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: props.queryInput,
            selectedFilter: '',
            placeholderText: 'Subject or P.E.',
        };

        this.updateInput = this.updateInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setSearchFocus();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.queryInput !== this.props.queryInput) {
            this.setState({
                query: nextProps.queryInput,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.focus !== prevProps.focus) {
            this.setSearchFocus();
        }
    }

    setSearchFocus() {
        this.searchField.focus();
    }

    getSearchParams() {
        const { query } = this.state;
        return [query];
    }

    handleSubmit(event) {
        event.preventDefault();
        const { query } = this.state;
        const onSearchPage = this.props.history.location.pathname === SearchUrl;

        if (query === '') {
            return false;
        }
        const { newQuery } = this.props;
        const params = this.getSearchParams();

        return onSearchPage ? newQuery(...params) : this.goToSearchPage(...params);
    }

    goToSearchPage(query, filter) {
        const searchQuery = query ? `&q=${encodeURI(query)}` : '';
        const searchFilter = filter ? `&f=f%7C${encodeURI(filter)}` : '';

        return this.props.history.push(`${SearchUrl}?page=1${searchQuery}${searchFilter}`);
    }

    updateInput(event) {
        this.setState({ query: event.target.value });
    }

    isDisabled() {
        return this.state.query.trim() === '';
    }

    render() {
        return (
            <div className="search-form__wrapper">
                <div className="search-form">
                    <form onSubmit={this.handleSubmit}>
                        <input
                          className=" search-form__input"
                          type="text"
                          value={this.state.query}
                          onChange={this.updateInput}
                          placeholder="Type keyword or topic, then use the filters"
                          ref={(input) => { this.searchField = input; }}
                        />
                        <button className="search-form__submit" disabled={this.isDisabled()}>
                            <span className="icon icon-search search-form__submit-icon" />
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

SearchForm.propTypes = {
    focus: PropTypes.string.isRequired,
    newQuery: PropTypes.func.isRequired,
    queryInput: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

SearchForm.defaultProps = {
    queryInput: '',
    setFocus: false,
    onSearchPage: false,
};

export default SearchForm;
