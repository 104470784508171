import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { ApolloProvider } from 'react-apollo';
import { ThemeProvider } from 'styled-components';
import { tstTheme } from '@twigeducation/ts-fe-components';
import 'what-input';
import { Provider } from 'react-redux';
import { initRaven, RavenWrapper } from '@twigeducation/raven-logger';
import Root from './routes';
import store from './store';
import client from './apolloClient/apolloClient';
import CustomUnleash from './components/Unleash';
import refreshAccessToken from './apolloClient/middleware/refreshAccessToken';

// Fonts and styles
require('./font');
require('../scss/main.scss');

if (typeof window.config.RAVEN_PUBLIC_DSN === 'string' && window.config.RAVEN_PUBLIC_DSN !== '') {
    initRaven(window.config.RAVEN_PUBLIC_DSN);
}

if (typeof window.config.GOOGLE_ANALYTICS === 'string' &&
    window.config.GOOGLE_ANALYTICS !== 'false' &&
    window.config.GOOGLE_ANALYTICS !== '') {
    ReactGA.initialize(window.config.GOOGLE_ANALYTICS);
} else {
    ReactGA.initialize('UA-00000000-00');
}

refreshAccessToken(true).then(() => {
    ReactDOM.render(
        <ApolloProvider client={client}>
            <Provider store={store}>
                <RavenWrapper>
                    <CustomUnleash>
                        <ThemeProvider theme={tstTheme}>
                            <Root path="/" />
                        </ThemeProvider>,
                    </CustomUnleash>
                </RavenWrapper>
            </Provider>
        </ApolloProvider>,
        document.getElementById('root'),
    );
});
