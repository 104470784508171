import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LogoutButton } from '@twigeducation/ts-auth';
import { TSTLoginUrl } from '../../../apps/Authentication/urls';
import SalesHomepageUrl from '../../../pages/SalesHomepage/url';

require('./scss/HeaderUnauthorized.scss');

const HeaderUnauthorized = props =>
     (
         <header className="sales-header" style={props.style}>
             <div className="sales-header__container">
                 <div className="sales-header__logo-wrapper">
                     <Link to={SalesHomepageUrl} className="sales-header__logo">
                         <h1 className="show-for-sr">Twig Science Tools</h1>
                     </Link>
                 </div>
                 <nav className="hide-for-print sales-header__main-menu">
                     <ul>
                         <li><Link to="/about-us">About us</Link></li>
                         <li><Link to="/contact-us">Contact us</Link></li>
                         {props.isAuthenticated ? (
                             <React.Fragment>
                                 <li>
                                     <LogoutButton
                                       className="sales-header__btn sales-header__close-btn"
                                       logout={props.onSignOut}
                                     >
                                        Sign out
                                         <span aria-hidden className="icon icon-close" />
                                     </LogoutButton>
                                 </li>
                             </React.Fragment>
                        ) : (
                            <li>
                                <Link to={TSTLoginUrl} className="sales-header__btn">
                                    Log in
                                    <span aria-hidden className="icon icon-arrow-right" />
                                </Link>
                            </li>
                        )}
                     </ul>
                 </nav>
             </div>
         </header>
    );

HeaderUnauthorized.defaultProps = {
    style: {},
    isAuthenticated: false,
    isUserLoggedIn: false,
};

HeaderUnauthorized.propTypes = {
    style: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    isAuthenticated: PropTypes.bool,
    onSignOut: PropTypes.func.isRequired,
};

export default withRouter(HeaderUnauthorized);

