import React from 'react';
import PropTypes from 'prop-types';
import twigScience from '../../images/twig-science.svg';

require('./scss/PrintLayout.scss');

const PrintLayout = props => (
    <div className="print-layout">
        <img src={twigScience} alt="Twig Science" className="print-layout__logo" />
        {props.children}
    </div>
);

PrintLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

export default PrintLayout;
