const resetPasswordUrl = '/password-reset/confirm/:uid/:token';
const TSForgotPasswordUrl = '/ts-forgot-password';
const TSLoginUrl = '/login';
const TSTForgotPasswordUrl = '/forgot-password';
const TSTLoginUrl = '/log-in';
const updatePasswordUrl = '/update-password';
const cleverUrl = '/oauth2/clever';
const classLinkUrl = '/oauth2/classlink';

export {
    resetPasswordUrl,
    TSForgotPasswordUrl,
    TSLoginUrl,
    TSTForgotPasswordUrl,
    TSTLoginUrl,
    updatePasswordUrl,
    cleverUrl,
    classLinkUrl,
};
