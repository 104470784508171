import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { updateUserInfo, clearUserInfo, handleLogout } from '@twigeducation/ts-auth';
import { TSTLoginUrl } from '../../apps/Authentication/urls';
import authenticationConfig from '../../authenticationConfig';
import refreshAccessToken from '../../apolloClient/middleware/refreshAccessToken';

const withAuthentication = (Wrapped) => {
    class AuthenticationFlow extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                redirect: false,
            };

            this.updateAuthentication = this.updateAuthentication.bind(this);
            this.redirectAuthorized = this.redirectAuthorized.bind(this);
            this.isUserAuthenticated = this.isUserAuthenticated.bind(this);
            this.isUserAuthorized = this.isUserAuthorized.bind(this);
            this.logoutAndClearUserInfo = this.logoutAndClearUserInfo.bind(this);
            this.forceRefreshAndUpdateAuthentication = this.forceRefreshAndUpdateAuthentication.bind(this);
        }

        componentWillReceiveProps(nextProps) {
            const previous = this.props.currentAuthenticationState;
            const next = nextProps.currentAuthenticationState;

            const authenticationChanged = previous !== next;
            const shouldRedirect = !this.isRedirectIgnored(previous, next);

            if (authenticationChanged && shouldRedirect) {
                this.navigateToNextStep(next);
            } else {
                this.setState({ redirect: false });
            }
        }

        isRedirectIgnored(previous, next) {
            const isIgnored = ignoredRedirect => (previous === ignoredRedirect.from && next === ignoredRedirect.to);
            const ignored = authenticationConfig.doNotRedirect.filter(isIgnored);

            return Boolean(ignored.length);
        }

        navigateToNextStep(nextAuthenticationState) {
            const defaultUrl = authenticationConfig.redirects.defaultState;
            const nextUrl = authenticationConfig.redirects[nextAuthenticationState] || defaultUrl;
            if (nextUrl !== this.state.redirect) {
                this.setState({ redirect: nextUrl });
            } else {
                this.setState({ redirect: false });
            }
        }

        updateAuthentication() {
            return this.props.updateUserInfoAction();
        }

        forceRefreshAndUpdateAuthentication() {
            return refreshAccessToken(true)
                .then(result => result)
                .catch(err => console.log(err));
        }

        redirectAuthorized() {
            if (this.isUserAuthenticated()) {
                const userState = this.props.currentAuthenticationState;
                this.navigateToNextStep(userState);
            }
        }

        isUserAuthenticated() {
            return this.props.isUserLoggedIn;
        }

        isUserAuthorized() {
            return this.props.currentAuthenticationState === authenticationConfig.authorizedAuthenticationState;
        }

        logoutAndClearUserInfo() {
            handleLogout();
            this.props.clearUserInfoAction();
        }

        render() {
            const { redirect } = this.state;
            if (redirect) {
                return <Redirect to={redirect} />;
            }

            return (
                <Wrapped
                  {...this.props}
                  updateAuthentication={this.updateAuthentication}
                  redirectAuthorized={this.redirectAuthorized}
                  logout={this.logoutAndClearUserInfo}
                  updateUserInfo={this.props.updateUserInfoAction}
                  isUserAuthorized={this.isUserAuthorized()}
                  isUserAuthenticated={this.isUserAuthenticated()}
                  loginUrl={TSTLoginUrl}
                  forceRefreshAndUpdateAuthentication={this.forceRefreshAndUpdateAuthentication}
                />);
        }
    }

    AuthenticationFlow.propTypes = {
        currentAuthenticationState: PropTypes.string,
        clearUserInfoAction: PropTypes.func.isRequired,
        updateUserInfoAction: PropTypes.func.isRequired,
        isUserLoggedIn: PropTypes.bool,
    };

    AuthenticationFlow.defaultProps = {
        currentAuthenticationState: authenticationConfig.defaultAuthenticationState,
        isUserLoggedIn: false,
    };

    const mapStateToProps = state => ({
        currentAuthenticationState: state.userInfo.signupNextStep,
        isUserLoggedIn: Boolean(state.userInfo.id),
    });

    const mapDispatchToProps = {
        clearUserInfoAction: clearUserInfo,
        updateUserInfoAction: updateUserInfo,
    };

    const withStore = connect(mapStateToProps, mapDispatchToProps);

    return withStore(AuthenticationFlow);
};

export default withAuthentication;
