import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Layout from './';
import PrintLayout from './PrintLayout';

const LayoutRoute = ({ component: Component, ...otherProps }) => (
    <Route
      {...otherProps}
      render={matchProps => (
          <Layout>
              <Component {...matchProps} />
          </Layout>
      )}
    />
);

LayoutRoute.propTypes = {
    component: PropTypes.func.isRequired,
};

const PrintLayoutRoute = ({ component: Component, ...otherProps }) => (
    <Route
      {...otherProps}
      render={matchProps => (
          <PrintLayout>
              <Component {...matchProps} />
          </PrintLayout>
      )}
    />
);

PrintLayoutRoute.propTypes = {
    component: PropTypes.func.isRequired,
};

export { LayoutRoute, PrintLayoutRoute };
