import { ApolloClient } from 'apollo-client';
import fetch from 'unfetch/dist/unfetch';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { ApolloLink, from as compileLink } from 'apollo-link';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import schema from '../../../schema.json';
import logUserOutOn401 from './afterware/logUserOutOn401';
import setProductCode from './middleware/setProductCode';
import refreshAccessTokenMiddleware from './middleware/refreshAccessToken';
import setApolloClientHeaders from './middleware/setApolloClientHeaders';

const httpLink = new BatchHttpLink({
    uri: window.config.PUBLIC_GRAPHQL_URL,
    credentials: 'omit',
    fetch,
});

const prepareHeadersLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
    });
    return forward(operation);
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema,
});

const link = compileLink([
    setContext(refreshAccessTokenMiddleware),
    prepareHeadersLink,
    new ApolloLink(setApolloClientHeaders),
    new ApolloLink(setProductCode),
    httpLink,
]);

const errorLink = onError(({ graphQLErrors }) => {
    logUserOutOn401(graphQLErrors);
});

const client = new ApolloClient({
    link: errorLink.concat(link),
    cache: new InMemoryCache({ fragmentMatcher }),
    // Upgrading client? Please set 'name' and 'version' here (see 'setApolloClientHeaders' for details)
});

export default client;
