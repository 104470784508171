import React from 'react';
import PropTypes from 'prop-types';
import { Unleash } from '@twigeducation/unleash-client-react';
import { connect } from 'react-redux';
import getUser from './getUser';

const UnleashWrapper = ({
    id,
    role,
    isStaff,
    testUser,
    schoolId,
    districtId,
    children,
}) => (
    <Unleash
      url={window.config.UNLEASH_URL}
      clientId={window.config.UNLEASH_CLIENT_ID}
      user={getUser(
            id,
            role,
            isStaff,
            testUser,
            schoolId,
            districtId,
        )}
    >
        {children}
    </Unleash>
);

UnleashWrapper.defaultProps = {
    id: 'genericAnonymousUser',
    role: 'ANONYMOUS',
    isStaff: false,
    testUser: false,
    schoolId: null,
    districtId: null,
};

UnleashWrapper.propTypes = {
    id: PropTypes.string,
    role: PropTypes.string,
    isStaff: PropTypes.bool,
    testUser: PropTypes.bool,
    schoolId: PropTypes.string,
    districtId: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.node,
        ),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
};

const mapStateToProps = state => ({
    id: state.userInfo.id,
    role: state.userInfo.role,
    schoolId: state.userInfo.schoolId,
    isStaff: state.userInfo.isStaff,
    testUser: state.userInfo.testUser,
    districtId: state.userInfo.districtId,
});


export default connect(mapStateToProps)(UnleashWrapper);
