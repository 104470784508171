const focusOnSearchBar = (state = {}, action) => {
    switch (action.type) {
    case 'FOCUS_ON_SEARCH_BAR':
        return action.value;
    default:
        return state;
    }
};

export default focusOnSearchBar;
