import React from 'react';
import { LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';
import SearchURL from './url';

const Search = withLoading(() => import('./'));

const route = <LayoutRoute path={SearchURL} component={Search} />;

export default route;
