import React from 'react';
import { LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';
import ContactUsUrl from './url';

const ContactUs = withLoading(() => import('./'));

const route = <LayoutRoute path={ContactUsUrl} component={ContactUs} />;

export default route;
