import React from 'react';
import { LayoutRoute, PrintLayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';

const Film = withLoading(() => import('./'));
const FilmPrint = withLoading(() => import('./PrintTranscript'));

const route = (
    <LayoutRoute
      path="/video/:slug([-\w]+)-:id([A-Za-z0-9\=]+)"
      exact
      component={Film}
    />
);
const FilmPrintRoutes = (
    <PrintLayoutRoute
      path="/video/:slug([-\w]+)-:id([A-Za-z0-9\=]+)/transcript/print"
      component={FilmPrint}
    />
);

export { FilmPrintRoutes };
export default route;
