import React from 'react';
import { LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';

const NotFoundComponent = withLoading(() => import('./'));

const LoadableNotFound = () => <NotFoundComponent />;

const route = <LayoutRoute path="*" component={LoadableNotFound} />;

export default route;
