import { createStore, combineReducers } from 'redux';
import undoable from 'redux-undo';
import { userInfoReducer } from '@twigeducation/ts-auth';
import authenticationConf from './authenticationConfig';
import searchArguments from './apps/Search/reducers';
import showSearchBar from './reducers/searchBar';
import focusOnSearchBar from './reducers/focusOnSearchBar';
import headerHeight from './reducers/headerHeight';

const reducers = combineReducers({
    searchArguments: undoable(searchArguments),
    showSearchBar,
    focusOnSearchBar,
    userInfo: userInfoReducer,
    headerHeight,
});

const initialState = {
    showSearchBar: false,
    focusOnSearchBar: '',
    searchArguments: {
        past: [],
        present: {},
        future: [],
    },
    userInfo: {
        signupNextStep: authenticationConf.defaultAuthenticationState,
    },
    headerHeight: 0,
};

export default createStore(reducers, initialState);
