import React from 'react';
import { LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';

const ActivitySheet = withLoading(() => import('./'));

const route = (
    <LayoutRoute
      path="/activity-sheet/:slug([-\w]+)-:id([A-Za-z0-9\=]+)"
      component={ActivitySheet}
    />
);

export default route;
