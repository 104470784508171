export const setHeader = ({ headers = {} }) => ({
    headers: {
        ...headers,
        // x-product-code is deprecated
        'x-product-code': window.config.PRODUCT_CODE,
        'x-product-variant-code': window.config.PRODUCT_VARIANT_CODE,
    },
});

export default (operation, forward) => {
    if (window.config.PRODUCT_VARIANT_CODE) {
        operation.setContext(setHeader);
    }
    return forward(operation);
};
