import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import newQuery from '../../actions/newQuery';
import SearchForm from './SearchForm';
import getSearchTerm from '../../apps/Search/helpers/getSearchTerm';

const mapStateToProps = state => ({
    queryInput: getSearchTerm(state),
    focus: state.focusOnSearchBar,
});

const mapDispatchToProps = dispatch => ({
    newQuery: (query, filter) => dispatch(newQuery(query, filter)),
});

const SearchFormContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));

export default SearchFormContainer;
