import {
    updateUserInfo,
    clearUserInfo,
    handleLogout,
    requestAccessTokenWithRefreshToken,
    requestAnonymousToken,
    accessTokenIsValid,
    refreshTokenIsValid,
    setAccessToken,
    setRefreshToken,
} from '@twigeducation/ts-auth';
import store from '../../store';

const destroySession = () => {
    handleLogout();
    store.dispatch(clearUserInfo());
};

export default async (forceRefresh = false) => {
    const isAccessTokenValid = accessTokenIsValid();
    const isRefreshTokenValid = refreshTokenIsValid();
    if ((!isAccessTokenValid && isRefreshTokenValid) || (forceRefresh && isRefreshTokenValid)) {
        try {
            const res = await requestAccessTokenWithRefreshToken();
            setAccessToken(res.data.access_token);
            setRefreshToken(res.data.refresh_token);
            store.dispatch(updateUserInfo());
            return res;
        } catch (e) {
            destroySession();
            throw new Error(e);
        }
    } else if (!isAccessTokenValid && !isRefreshTokenValid) {
        destroySession();
        try {
            const res = await requestAnonymousToken();
            setAccessToken(res.signed_token);
            return res;
        } catch (e) {
            destroySession();
            throw new Error(e);
        }
    } else {
        store.dispatch(updateUserInfo());
        return true;
    }
};
