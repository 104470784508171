import React from 'react';
import { Route } from 'react-router-dom';
import { LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';

const Activity = withLoading(() => import('./'));
const ClassroomView = withLoading(() => import('./ClassroomView'));

const route = (
    <LayoutRoute
      path="/activity/:slug([-\w]+)-:id([A-Za-z0-9\=]+)"
      exact
      component={Activity}
    />
);

const ClassroomViewRoute = (
    <Route
      path="/activity/:slug([-\w]+)-:id([A-Za-z0-9\=]+)/classroom-view"
      component={ClassroomView}
    />
);

export { ClassroomViewRoute };
export default route;
