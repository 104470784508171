import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import AboutRoute from './pages/About/routes';
import AccessibilityRoute from './pages/Accessibility/routes';
import ActivityRoutes, { ClassroomViewRoute } from './apps/Activity/routes';
import ActivitySheetRoutes from './apps/ActivitySheet/routes';
import ContactUsRoute from './apps/ContactUs/routes';
import LessonRoutes from './apps/Lesson/routes';
import FilmRoutes, { FilmPrintRoutes } from './apps/Film/routes';
import GameRoutes from './apps/Games/routes';
import LoginRoutes from './apps/Authentication/routes';
import NotFoundRoute from './pages/NotFound/routes';
import QuizRoutes from './apps/Quiz/routes';
import { VisualRoute, VisualPrintRoute } from './apps/Visual/routes';
import SalesHomepageRoute from './pages/SalesHomepage/routes';
import SearchRoutes from './apps/Search/routes';
import TeacherInformationRoutes from './apps/TeacherInformation/routes';


const history = createBrowserHistory();

if (typeof window.config.GOOGLE_ANALYTICS === 'string' &&
    window.config.GOOGLE_ANALYTICS !== 'false' &&
    window.config.GOOGLE_ANALYTICS !== '') {
    history.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });
}

const scrollToTop = () => window.scrollTo(0, 0);

const Routes = () => (
    <Router onUpdate={scrollToTop}>
        <Switch>
            {SalesHomepageRoute}
            {LoginRoutes}
            {AboutRoute}
            {AccessibilityRoute}
            {ContactUsRoute}
            {FilmRoutes}
            {FilmPrintRoutes}
            {ActivityRoutes}
            {ActivitySheetRoutes}
            {GameRoutes}
            {LessonRoutes}
            {QuizRoutes}
            {SearchRoutes}
            {VisualRoute}
            {TeacherInformationRoutes}
            {ClassroomViewRoute}
            {VisualPrintRoute}
            {NotFoundRoute}
        </Switch>
    </Router>
);

export default Routes;
export { history };
