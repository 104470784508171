import { without } from 'lodash';
import SearchUrl from '../url';

const defaultPage = { page: 1 };

const addFilter = (previousInputs, id) => {
    const inputs = [
        ...previousInputs.inputs,
        { queryType: 'filter', value: id },
    ];
    return { inputs, ...defaultPage };
};

const getFilter = (previousInputs, id) => (
    previousInputs.inputs.find(option => (
        option.queryType === 'filter' &&
        option.value === id
    ))
);

const toggleFilter = (previousInputs, id) => {
    const existing = getFilter(previousInputs, id);

    if (existing) {
        const inputs = without(previousInputs.inputs, existing);
        return { inputs, ...defaultPage };
    }

    return addFilter(previousInputs, id);
};

const addRefinement = (previousInputs, id) => {
    const exists = previousInputs.inputs.find(option => (
        option.queryType === 'refinement' &&
        option.value === id
    ));

    if (exists) {
        return previousInputs;
    }

    const inputs = [
        ...previousInputs.inputs,
        { queryType: 'refinement', value: id },
    ];

    return { inputs, ...defaultPage };
};

const updatePage = (previousInputs, id) => {
    const inputs = previousInputs.inputs;
    return { inputs, page: id };
};

const searchArguments = (previousInputs = [], action) => {
    switch (action.type) {
    case 'SET_SEARCH_ARGUMENTS': {
        return action.value;
    }

    case 'UPDATE_RESULTS_PAGE': {
        return { ...previousInputs, page: action.page };
    }

    case 'UPDATE_SEARCH_INPUT': {
        if (action.queryType === 'page') {
            return updatePage(previousInputs, action.value);
        }

        if (action.queryType === 'filter') {
            return toggleFilter(previousInputs, action.value);
        }

        if (action.queryType === 'refinement') {
            return addRefinement(previousInputs, action.value);
        }

        throw new Error('Unrecognized search input type');
    }

    case 'REMOVE_FILTERS': {
        const valuesToRemove = action.filters.map(item => item.value);
        const inputs = previousInputs.inputs.filter(item => (
            !valuesToRemove.includes(item.value)
        ));
        return { inputs, page: previousInputs.page };
    }

    case 'NEW_QUERY': {
        const inputs = [{ queryType: 'query', value: action.query }];

        if (action.filter) {
            inputs.push({ queryType: 'filter', value: action.filter });
        }

        return { inputs, ...defaultPage };
    }

    case 'REMOVE_SEARCH_INPUT': {
        const inputs = previousInputs.inputs.filter(input => (
            input.value !== action.value ||
            input.queryType === 'query'
        ));
        return { inputs, ...defaultPage };
    }

    case 'REMOVE_ALL_INPUTS': {
        const inputs = previousInputs.inputs.filter(input => (
            input.queryType === 'query'
        ));
        return { inputs, ...defaultPage };
    }

    case '@@router/LOCATION_CHANGE': {
        if (action.payload.pathname !== SearchUrl) {
            return { inputs: [], ...defaultPage };
        }

        return previousInputs;
    }

    case 'TOGGLE_SEARCH_BAR': {
        return { inputs: [], ...defaultPage };
    }

    case 'UPDATE_QUERY_INPUT': {
        const inputsWithoutQuery = previousInputs.inputs.filter(input => (
            input.queryType !== 'query'
        ));

        return {
            inputs: [
                ...inputsWithoutQuery,
                { queryType: 'query', value: action.query },
            ],
            ...defaultPage,
        };
    }

    default: {
        return previousInputs;
    }

    }
};

export default searchArguments;
