import React from 'react';
import PropTypes from 'prop-types';

import HeaderSwitcher from './HeaderSwitcher';

require('./scss/Header.scss');

class Header extends React.Component {
    componentDidMount() {
        this.props.setHeaderHeight(this.header.clientHeight || 0);
    }

    render() {
        return (
            <div
              className="site-header-container"
              style={this.props.style}
              ref={(DOMElement) => { this.header = DOMElement; }}
            >
                <HeaderSwitcher />
            </div>
        );
    }
}

Header.defaultProps = {
    style: {},
};

Header.propTypes = {
    setHeaderHeight: PropTypes.func.isRequired,
    style: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};

export default Header;
