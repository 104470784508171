import { TSTLoginUrl } from './apps/Authentication/urls';
import SearchUrl from './apps/Search/url';

const defaultAuthenticationState = 'anonymous';
const authorizedAuthenticationState = 'authorized';

const authenticationConfig = {
    defaultAuthenticationState,
    authorizedAuthenticationState,
    redirects: {
        defaultState: TSTLoginUrl,
        [defaultAuthenticationState]: TSTLoginUrl,
        authorized: SearchUrl,
    },
    doNotRedirect: [
        { from: defaultAuthenticationState, to: 'addDetails' },
        { from: 'addDetails', to: 'verifyEmail' },
        { from: 'verifyEmail', to: 'authorized' },
    ],
};

export default authenticationConfig;
