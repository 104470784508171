// Oct 12, 2020:
// The ApolloClient headers set here (name and version) are needed for graph usage metrics on Apollo Studio.
// Ideally, they should be set in the ApolloClient constructor: https://bit.ly/3nDRkzz.
// It doesn't work with the version we're using - 2.4.8, and/or its combination with apollo-link and batch-link libs
// When upgrading to the latest client version, we should try to pass in these values via the constructor.
// If it works (ie., graphql requests send these headers), we should remove the manual setting of headers here.

export const setHeader = ({ headers = {} }) => ({
    headers: {
        ...headers,
        'apollographql-client-name': 'twigscience-tools',
        'apollographql-client-version': window.config.IMAGE_GIT_COMMIT_SHA,
    },
});

export default (operation, forward) => {
    operation.setContext(setHeader);
    return forward(operation);
};
