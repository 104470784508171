import React from 'react';
import { PrintLayoutRoute, LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';

const Visual = withLoading(() => import('./'));
const VisualPrint = withLoading(() => import('./VisualPrint'));

const VisualPrintRoute = (
    <PrintLayoutRoute
      path="/visual/:type/:slug([-\w]+)-:id([A-Za-z0-9\=]+)/print"
      component={VisualPrint}
    />
);

const VisualRoute = (
    <LayoutRoute
      path="/visual/:type/:slug([-\w]+)-:id([A-Za-z0-9\=]+)"
      component={Visual}
      exact
    />
);

export { VisualRoute, VisualPrintRoute };
