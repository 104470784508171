import SeachUrl from '../apps/Search/url';

const moveToSearchPage = ({ pathname }) => pathname.startsWith(SeachUrl);

const showSearchBar = (state = {}, action) => {
    switch (action.type) {
    case 'TOGGLE_SEARCH_BAR':
        return !state;
    case 'SHOW_SEARCH_BAR':
        return true;
    case '@@router/LOCATION_CHANGE':
        return moveToSearchPage(action.payload) ? state : false;
    default:
        return state;
    }
};

export default showSearchBar;
