import React from 'react';
import {
    resetPasswordUrl,
    TSForgotPasswordUrl,
    TSLoginUrl,
    TSTForgotPasswordUrl,
    TSTLoginUrl,
    updatePasswordUrl,
    cleverUrl,
    classLinkUrl,
} from './urls';
import { LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';

const CreatePassword = withLoading(() => import('./CreatePassword'));
const ForgotPassword = withLoading(() => import('./ForgotPassword'));
const Login = withLoading(() => import('./Login'));
const ResetPassword = withLoading(() => import('./ResetPassword'));
const TSForgotPassword = withLoading(() => import('./TSForgotPassword'));
const TSLogin = withLoading(() => import('./TSLogin'));
const CleverOAuth2 = withLoading(() => import('./OAuth2/Clever'));
const ClassLinkOAuth2 = withLoading(() => import('./OAuth2/ClassLink'));

const routes = [
    <LayoutRoute path={TSTLoginUrl} exact component={Login} key={TSTLoginUrl} />,
    <LayoutRoute path={TSForgotPasswordUrl} component={TSForgotPassword} key={TSForgotPasswordUrl} />,
    <LayoutRoute path={TSTForgotPasswordUrl} component={ForgotPassword} key={TSTForgotPasswordUrl} />,
    <LayoutRoute path={resetPasswordUrl} component={ResetPassword} key={resetPasswordUrl} />,
    <LayoutRoute path={updatePasswordUrl} component={CreatePassword} key={updatePasswordUrl} />,
    <LayoutRoute path={`${TSLoginUrl}/:districtId`} exact component={TSLogin} key={TSLoginUrl} />,
    <LayoutRoute path={TSLoginUrl} component={TSLogin} key={TSLoginUrl} />,
    <LayoutRoute path={cleverUrl} exact component={CleverOAuth2} key={cleverUrl} />,
    <LayoutRoute path={classLinkUrl} exact component={ClassLinkOAuth2} key={classLinkUrl} />,
];

export default routes;
