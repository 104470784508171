import { connect } from 'react-redux';

import Header from './Header';
import setHeaderHeight from '../../actions/headerHeight';

const mapDispatchToProps = dispatch => ({
    setHeaderHeight: height => dispatch(setHeaderHeight(height)),
});

export default connect(null, mapDispatchToProps)(Header);
