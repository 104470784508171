import React from 'react';
import { LayoutRoute } from '../../layout/routes';
import withLoading from '../../helpers/withLoading';

const TeacherInformation = withLoading(() => import('./'));

const route = (
    <LayoutRoute
      path="/teacher-information/:slug([-\w]+)-:id([A-Za-z0-9\=]+)"
      component={TeacherInformation}
    />
);

export default route;
