import { compose } from 'redux';
import withAuthentication from '../../../components/withAuthentication';

import HeaderSwitcher from './HeaderSwitcher';

const enhance = compose(
    withAuthentication,
);

export default enhance(HeaderSwitcher);
