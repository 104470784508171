import React from 'react';
import PropTypes from 'prop-types';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { LogoutButton } from '@twigeducation/ts-auth';
import SearchUrl from '../../../apps/Search/url';
import SearchForm from '../../../components/SearchForm';

require('./scss/HeaderDropdown.scss');
require('./scss/HeaderAuthorized.scss');

const HeaderAuthorized = props => (
    <header className="site-header">
        <div className="site-header__container">
            <div className="site-header__logo-wrapper">
                <a href={SearchUrl} className="site-header__logo">
                    <h1 className="show-for-sr">Twig Science Tools</h1>
                </a>
            </div>
            <nav className="hide-for-print site-header__main-menu">
                <ul className="site-header__menu">
                    <li className="site-header__menu-item">
                        <Dropdown>
                            <DropdownTrigger href="/" className="site-header__account-link">
                                <span className="icon icon-account site-header__menu-item-icon" />
                            </DropdownTrigger>
                            <DropdownContent>
                                <ul className="dropdown__content-list">
                                    <li className="dropdown__content-list-item">
                                        <LogoutButton className="sign-out-button" logout={props.onSignOut}>
                                            Sign out
                                        </LogoutButton>
                                    </li>
                                </ul>
                            </DropdownContent>
                        </Dropdown>
                    </li>
                    <li className="site-header__menu-item site-header__search"><SearchForm /></li>
                </ul>
            </nav>
        </div>
    </header>
);

HeaderAuthorized.propTypes = {
    onSignOut: PropTypes.func.isRequired,
};

export default HeaderAuthorized;
