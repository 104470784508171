const getSearchTerm = (state) => {
    const inputs = state.searchArguments.present.inputs;
    const query = inputs ? inputs.filter(input => (
        input.queryType === 'query'
    )) : [];

    return query.length ? query[0].value : '';
};

export default getSearchTerm;
