const headerHeight = (state = 0, action) => {
    switch (action.type) {
    case 'SET_HEADER_HEIGHT':
        return action.value;
    default:
        return state;
    }
};

export default headerHeight;
