import React from 'react';

require('./scss/Loading.scss');

const Loading = () => (
    <div className="loading">
        <div className="loading__wrapper">
            <div className="loading__twig-ball" />
            <p className="loading__text">Loading ...</p>
        </div>
    </div>
);

export default Loading;
